import Typography from "typography"


const typography = new Typography({
  baseFontSize: '16px',
  baseLineHeight: 1.66,
  headerFontFamily: ['Raleway'],
  bodyFontFamily: ['Open Sans'],
  scaleRatio: 2,
  googleFonts: [
    {
      name: 'Open Sans',
      styles: [
        '300',
        '400',
        '500',
        '600',
        '700'
      ],
    },
    {
      name: 'Raleway',
      styles: [ 
        '400',
        '700'
      ],
    }
  ],
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
